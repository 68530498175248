import React, { Component } from "react"
import { navigate } from "gatsby"
import LoadingOverlay from "react-loading-overlay"

const INITIAL_STATE = {
  name: "",
  email: "",
  phone_number: "",
  msg_subject: "",
  message: "",
  loading: false,
}

class ContactForm extends Component {
  constructor(props) {
    super(props)

    this.state = { ...INITIAL_STATE }
  }

  sendMessage(messageInfo) {
    let that = this
    fetch("/api/contactForm", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        messageInfo: messageInfo,
      }),
    }).then(function(result) {
      that.setState({ loading: false })
      navigate("/contact-confirm")
    })
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  onSubmit = event => {
    this.setState({ loading: true })
    const { name, email, phone_number, msg_subject, message } = this.state

    const messageInfo = {
      name: name,
      email: email,
      phone_number: phone_number,
      subject: msg_subject,
      message: message,
    }

    this.sendMessage(messageInfo)

    event.preventDefault()
  }

  render() {
    const {
      name,
      email,
      phone_number,
      msg_subject,
      message,
    } = this.state

    return (
      <div className="contact-form">
        <h3>We're happy to help!</h3>

        <MyLoader active={this.state.loading}>
          <form id="contactForm" onSubmit={this.onSubmit}>
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="form-group">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    className="form-control"
                    placeholder="Your Name"
                    value={name}
                    onChange={this.onChange}
                    required
                  />
                </div>
              </div>

              <div className="col-lg-6 col-md-6">
                <div className="form-group">
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="form-control"
                    placeholder="Your Email"
                    value={email}
                    onChange={this.onChange}
                    required
                  />
                </div>
              </div>

              <div className="col-lg-6 col-md-6">
                <div className="form-group">
                  <input
                    type="text"
                    name="phone_number"
                    id="phone_number"
                    className="form-control"
                    placeholder="Your Phone"
                    value={phone_number}
                    onChange={this.onChange}
                    required
                  />
                </div>
              </div>

              <div className="col-lg-6 col-md-6">
                <div className="form-group">
                  <input
                    type="text"
                    name="msg_subject"
                    id="msg_subject"
                    className="form-control"
                    placeholder="Your Subject"
                    value={msg_subject}
                    onChange={this.onChange}
                    required
                  />
                </div>
              </div>

              <div className="col-lg-12 col-md-12">
                <div className="form-group">
                  <textarea
                    name="message"
                    className="form-control"
                    id="message"
                    rows="6"
                    placeholder="Your Message"
                    value={message}
                    onChange={this.onChange}
                    required
                  ></textarea>
                </div>
              </div>

              <div className="col-lg-12 col-md-12 text-center">
                <button type="submit" className="default-btn">
                  <i className="bx bxs-paper-plane"></i>
                  Send Message
                  <span></span>
                </button>
              </div>
            </div>
          </form>
        </MyLoader>
      </div>
    )
  }
}

function MyLoader({ active, children }) {
  return (
    <LoadingOverlay
      active={active}
      spinner
      styles={{
        overlay: base => ({
          ...base,
          background: "rgba(255, 255, 255, 0.5)",
        }),
        spinner: base => ({
          ...base,
          width: "100px",
          "& svg circle": {
            stroke: "rgba(199, 59, 27, 1)",
          },
        }),
      }}
    >
      {children}
    </LoadingOverlay>
  )
}

export default ContactForm
