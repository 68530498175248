import React, { Component } from "react"

class HowToContact extends Component {
  render() {
    return (
      <section className="contact-area">
        <div className="container">
          <div className="contact-inner">
            <div className="row align-items-center">
              <div className="col-lg-12 col-md-12"></div>
              <h2>How to request help</h2>
              <p>
                Sprinkler systems save lives. We want you to be successful and
                to put in functioning and efficient sprinkler systems. We also
                want HASS to work properly. If you have a question about how
                HASS works or don't think HASS is working properly, we want to
                know so it can be corrected. However, we can't answer your
                design questions except on how to implement a feature in HASS.
                This is due to HRS Systems staff not being the designer or
                Engineer of Record, thus not having enough information about the
                project to provide recommendations without knowing the full
                scope of the project.{" "}
              </p>
              <p>
                First, please review the FAQ above and the manual to see if the
                answer to your question is there. If not, please send us an
                email to{" "}
                <a href="mailto:hass@hrssystems.com">hass@hrssystems.com</a>{" "}
                with the following information:
              </p>
              <ul>
                <li>What you were doing when the problem occurred.</li>
                <li>What were you expecting HASS to do?</li>
                <li>What HASS did instead?</li>
                <li>
                  Attach any files involved. This should include the sdf and the
                  pipe table if it is not standard.pip that is shipped with
                  HASS.Also include information about your computer system:
                  <ul>
                    <li>Operating System Version - which version of Windows</li>
                    <li>What security software you are using</li>
                    <li>
                      Screen Shots of relevant options - especially the All
                      Directories Dialog and the Operations Options Dialog.{" "}
                    </li>
                  </ul>
                </li>

                <li>
                  Your best contact information and good times to contact you.
                  If you are not computer savvy, please try to have someone who
                  is good with computers nearby.{" "}
                </li>
              </ul>
              <p>
                If possible, please try to repeat the process to limit the
                number of steps involved to as few as possible.{" "}
              </p>
              <p>
                Just because we can't repeat the defect doesn't mean it isn't a
                real issue. It does mean that we may have questions about your
                system configuration.
              </p>
              <p>
                It is helpful for us to see what is happening on your computer,
                and it is easier for us to help you resolve the issue if we can
                see. There are two options for this. The best option is a tool
                from Microsoft called Quick Access. Up to date Windows 10
                systems will have this program available. Otherwise, we
                generally set up a Google Meet option. If neither of those work,
                please suggest a tool that is available on your computer.{" "}
              </p>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default HowToContact
