import React, { Component } from "react"

class ContactInfo extends Component {
  render() {
    return (
      <div className="contact-info">
        <div className="contact-info-content">
          <h3>Contact us by Phone or Email:</h3>

          <h2>
            <span className="number">+1 (931) 659-9760</span>
            <span className="or">OR</span>
            <span className="email">hass@hrssystems.com</span>
          </h2>

          <ul className="social">
            <li>
              <a href="https://www.facebook.com/hrssystems/" target="_blank">
                <i className="bx bxl-facebook"></i>
              </a>
            </li>
            <li>
              <a href="https://www.youtube.com/channel/UCofSqjMfCpXchWmJL59QtxA" target="_blank">
                <i className="bx bxl-youtube"></i>
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/hrs-systems-inc-/about/" target="_blank">
                <i className="bx bxl-linkedin"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    )
  }
}

export default ContactInfo
