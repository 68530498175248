import React, { Component } from "react"
import { graphql } from "gatsby"
import PageTitleArea from "../components/Common/PageTitleArea"
import ContactFormArea from "../components/Contact/ContactFormArea"
// import OurLovingClients from '../components/Common/OurLovingClients';
import Layout from "../components/layout"
import FaqContentThree from "../components/Faq/FaqContentThree"
import HowToContact from "../components/Contact/HowToContact"


export const query = graphql`
query contactPageQuery {
  page: sanityPage(_id: {eq: "contactPage"}) {
    seo {
      title
      image {
        asset {
          url
        }
      }
      description
    }
  }
}
`

const Contact = props => {
  const seo = props.data?.page?.seo
  return (
    <Layout title={seo.title} description={seo.description} image={seo.image}>
      <PageTitleArea pageTitle="Contact Us" />
      <FaqContentThree />
      <HowToContact />
      <ContactFormArea />
      {/*<OurLovingClients />*/}
    </Layout>
  )
}

export default Contact
