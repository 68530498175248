import React, { Component } from "react"
import ContactForm from "./ContactForm"
import ContactInfo from "./ContactInfo"

class ContactFormArea extends Component {
  render() {
    return (
      <section className="contact-area ptb-100">
        <div className="container">
          <div className="contact-inner">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div className="contact-features-list">
                  <h3>Accuracy you can trust.</h3>
                  <p>
                    Make your calculations easier, faster, and more accurate with HASS.
                  </p>
                  <p>
                    We were built by engineers for engineers and take pride in our customer service. Feel free to reach out anytime with questions or new ideas for us-  we’d love to hear from you!
                  </p>

                  <ul>
                    <li>
                      <i className="bx bx-badge-check"></i>
                      Questions on bulk licensing?
                    </li>
                    <li>
                      <i className="bx bx-badge-check"></i>
                      Ideas for new training videos?
                    </li>
                    <li>
                      <i className="bx bx-badge-check"></i>
                      Questions about product specifications and functionality?
                    </li>
                    <li>
                      <i className="bx bx-badge-check"></i>
                      Comments about our products and how we can better serve you?
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-6 col-md-12">
                {/* Contact Form */}
                <ContactForm />
              </div>
            </div>

            {/* Contact Info */}
            <ContactInfo />
          </div>
        </div>
      </section>
    )
  }
}

export default ContactFormArea
